import { render, staticRenderFns } from "./UserListTW.vue?vue&type=template&id=98591bcc&scoped=true&"
import script from "./UserListTW.vue?vue&type=script&lang=js&"
export * from "./UserListTW.vue?vue&type=script&lang=js&"
import style0 from "./UserListTW.vue?vue&type=style&index=0&id=98591bcc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98591bcc",
  null
  
)

export default component.exports