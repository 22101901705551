<template>
  <div>
    <div style="padding: 12px; text-align: center">
      <h1>会员年卡剩余数量: {{order_num}}</h1>
      <div style="display: flex;justify-content: center;">
        <Button type="primary" @click="addUser">新建账户</Button>
        <Input style="width: 200px;margin-left:20px;"
            search
            enter-button
            placeholder="输入用户名查询"
            @on-search="onSearch"
          />
      </div>
      
    </div>
    <div class="user-list">
      <Table
        :columns="userColumns"
        :data="userData"
        border
        :max-height="tableMaxHeight"
      >
        <template slot-scope="{ row }" slot="name">
          <strong>{{ row.name }}</strong>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button
            type="primary"
            size="small"
            style="margin-right: 5px"
            @click="editUserPwd(index)"
            >修改密码</Button
          >
          <Button
            type="primary"
            size="small"
            style="margin-right: 5px"
            @click="reNew(index)"
            >续年卡</Button
          >
        </template>
      </Table>
      <div style="margin: 20px 12px">
        <div style="display: flex; flex-direction: row-reverse">
          <Page
            :total="totalCount"
            :current="currentPage"
            @on-change="changePage"
            :page-size="pageSize"
            show-total
          ></Page>
        </div>
      </div>
    </div>
    <Modal
      v-model="showEditPwd"
      title="修改密码"
      @on-ok="onPwdEditOk"
      :loading="editUserLoading"
    >
      <p style="margin-bottom: 12px">
        <span style="display: inline-block; width: 60px">账户:</span
        >{{ editUser.username }}
      </p>
      <span style="display: inline-block; width: 60px">新密码:</span
      ><Input
        v-model="inputPwd"
        placeholder="只允许输入数字和字母"
        style="width: 200px"
      />
    </Modal>

    <Modal
      v-model="showAddUser"
      title="新建账户"
      @on-ok="onAddUserOk"
      :loading="addUserLoading"
    >
      <div style="margin-bottom: 12px">
        <span style="display: inline-block; width: 60px">账户:</span>
        <Input
          v-model="inputUser"
          placeholder="请输入邮箱或手机号"
          style="width: 250px"
        />
      </div>
      <span style="display: inline-block; width: 60px">密码:</span
      ><Input
        v-model="inputPwd"
        placeholder="只允许输入数字和字母"
        style="width: 250px"
      />
    </Modal>
  </div>
</template>

<script>
import { mixinDevice } from "@/util/mixin";
import Store from "@/store";
import api from "@/api";

export default {
  name: "user-list",
  mixins: [mixinDevice],
  data() {
    let columnWidth = Store.state.deviceType == "mobile" ? 140 : "auto";
    return {
      userColumns: [
        {
          title: "用户名",
          key: "username",
          width: columnWidth,
        },
        {
          title: "注册日期",
          key: "register_time",
          width: columnWidth,
        },
        {
          title: "到期日期",
          key: "active_expired_time",
          width: columnWidth,
        },
        {
          title: "户型数量",
          key: "house_count",
          width: columnWidth,
        },
        {
          title: "操作",
          slot: "action",
          width: columnWidth,
          align: "center",
        },
      ],
      userData: [],
      currentPage: 1,
      totalCount: 0,
      pageSize: 50,
      brandType: 1,
      showEditPwd: false,
      inputPwd: "",
      editUser: "",
      inputUser: "",
      showAddUser: false,
      addUserLoading: true,
      editUserLoading: true,
      order_num: 0,
      search:''
    };
  },
  computed: {
    tableMaxHeight() {
      return this.isMobile() ? 540 : 700;
    },
  },
  methods: {
    onSearch(content) {
      this.$log.debug("search content: ", content);
      this.search = content.trim() || "";
      this.currentPage = 1;
      this.loadData();
    },
    addUser() {
      this.showAddUser = true;
      this.inputUser = '';
      this.inputPwd = '';
    },
    onAddUserOk() {
      if (this.inputUser.trim() === "") {
        this.$Message.error("请先输入账户名称！");
        this.addUserLoading = false;
        this.$nextTick(() => {
          this.addUserLoading = true;
        });
        return;
      }
      let result =
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
          this.inputUser.trim()
        );
      result = result | /^09\d{8}$/.test(this.inputUser.trim())
      if (!result) {
        this.$Message.error("账户输入不符合邮箱格式或手机号");
        this.addUserLoading = false;
        this.$nextTick(() => {
          this.addUserLoading = true;
        });
        return;
      }
      if (this.inputPwd.trim() === "") {
        this.$Message.error("请先输入密码！");
        this.addUserLoading = false;
        this.$nextTick(() => {
          this.addUserLoading = true;
        });
        return;
      }
      result = /^[ZA-ZZa-z0-9_]+$/.test(this.inputPwd.trim());
      if (!result) {
        this.$Message.error("密码输入不符合格式");
        this.addUserLoading = false;
        this.$nextTick(() => {
          this.addUserLoading = true;
        });
        return;
      }
      api
        .addTWUser(this.inputUser.trim(), this.inputPwd.trim())
        .then((res) => {
          this.$log.debug(res);
          if (res.code == "OK") {
            this.$Message.success("账户添加成功");
          } else {
            console.log("onadd error:------");
            this.$Message.error("账户添加失败:"+res.message);
          }
        })
        .catch((err) => {
          this.$log.debug(err);
          this.$Message.error("账户添加失败:"+err.message);
        });
      this.showAddUser = false;
      this.addUserLoading = true;
      this.currentPage = 1;
      this.loadData();
    },
    onPwdEditOk() {
      if (this.inputPwd.trim() === "") {
        this.$Message.error("请先输入新密码！");
        this.editUserLoading = false;
        this.$nextTick(() => {
          this.editUserLoading = true;
        });

        return;
      }
      let result = /^[A-Za-z0-9]+$/.test(this.inputPwd.trim());
      if (result) {
        api
          .changeTWPWD(this.editUser.username, this.inputPwd.trim())
          .then((res) => {
            this.$log.debug(res);
            if (res.code == "OK") {
              this.$Message.success("密码修改成功");
            } else {
              this.$Message.error("密码修改失败:"+res.message);
            }
          })
          .catch((err) => {
            this.$log.debug(err);
            this.$Message.error("密码修改失败");
          });
        this.editUserLoading = true;
        this.showEditPwd = false;
      } else {
        this.$Message.error("密码输入不符合格式");
        this.editUserLoading = false;
        this.$nextTick(() => {
          this.editUserLoading = true;
        });
      }
    },
    editUserPwd(idx) {
      let user = this.userData[idx];
      this.$log.debug(user);
      this.showEditPwd = true;
      this.editUser = user;
      this.inputPwd = "";
    },
    reNew(idx) {
      let user = this.userData[idx];
      this.$log.debug(user);
      this.$Modal.confirm({
        title: "会员续费",
        content: `<p>确定要给账户 <span style="color:red;font-size:20px;">${user.username}</span> 续年卡吗?</p>`,
        onOk: () => {
          api
            .twActiveUser(user.username)
            .then((res) => {
              this.$log.debug(res);
              if (res.code == "OK") {
                this.$Message.success("续费成功");
                this.loadData();
              } else {
                this.$Message.error("续费失败:" + res.message);
              }
            })
            .catch((err) => {
              this.$log.debug(err);
              this.$Message.error("续费失败");
            });
        },
        onCancel: () => {
          this.$Message.error("续费取消");
        },
      });
    },
    changePage(newPage) {
      this.currentPage = newPage;
      this.$log.debug("currentPage: ", this.currentPage);
      this.loadData();
    },
    loadData() {
      api
        .twUserList(12, this.currentPage, this.pageSize,this.search)
        .then((res) => {
          this.$log.debug(res);
          this.order_num = res.order_num;
          if (res.users && res.users.length > 0) {
            this.userData = res.users;
            this.totalCount = res.total_count;
          } else {
            this.userData = [];
            this.totalCount = 0;
          }
        })
        .catch((err) => {
          this.$log.debug(err);
        });
    },
  },
  mounted() {
    this.columnWidth = this.isMobile() ? 140 : "auto";
    this.loadData();
  },
};
</script>

<style lang="less" scoped>
.user-list {
  width: 100%;
  padding: 12px;

  .filter-container {
    margin-bottom: 12px;
    padding: 12px 24px;
    border: 1px dashed green;
    display: flex;

    .search-container {
      margin-left: auto;
    }

    .order-filter {
      margin-left: 12px;
    }

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;

      .search-container,
      .descend-container {
        margin: 0;
        margin-top: 12px;
      }
    }
  }
}
</style>